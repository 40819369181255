import { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './Search.module.scss';
import searchPageApi from '../../api/searchApi';
import FilmBlock from '../../layouts/components/public/FilmBlock';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
const cx = classNames.bind(styles);

function removeVietnameseTones(str) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
    str = str.replace(/Đ/g, 'D');

    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư

    str = str.replace(/ + /g, ' ');
    str = str.trim();

    str = str.replace(
        /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
        ' ',
    );
    return str;
}

function Search() {
    const [filmData, setFilmData] = useState([]);
    const [searchValue, setSearchValue] = useState();
    const [newArray, setNewArray] = useState([]);
    const [clear, setClear] = useState(false);

    useEffect(() => {
        const fetchSearchApi = async () => {
            try {
                const response = await searchPageApi.getAll();
                const { data } = response;
                setFilmData(data.films);
            } catch (error) {
                console.log('Failed to fetch product list: ', error);
            }
        };
        fetchSearchApi();
    }, []);

    const handleChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        setClear(true);
        const array = [];
        for (let i = 0; i < filmData.length; i++) {
            if (
                removeVietnameseTones(filmData[i].name)
                    .toLowerCase()
                    .includes(removeVietnameseTones(value).toLowerCase())
            ) {
                array.push(filmData[i]);
            }
        }

        setNewArray(array);
    };

    const handleClear = () => {
        setSearchValue('');
        setClear(false);
    };

    return (
        <div className={cx('wrapper')}>
            <div className={cx('search')}>
                <input
                    autoFocus
                    className={cx('input')}
                    onChange={(e) => handleChange(e)}
                    placeholder="Nhập tên phim..."
                    type="text"
                    value={searchValue}
                />

                {clear && (
                    <HighlightOffOutlinedIcon
                        className={cx('icon', 'clear')}
                        onClick={() => handleClear()}
                    />
                )}
            </div>

            <FilmBlock films={newArray.length > 0 ? newArray : filmData} />
        </div>
    );
}

export default Search;
